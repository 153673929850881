<template>
    <b-card title="Default Tax Rates">
        <b-form>
            <b-row class="border">
                <b-col md="12">
                    <b-form-group label="Store Name">
                        <v-select v-model="storeName" label="storeName" placeholder="Select Store Name"
                            :options="storeNameOptions" />
                    </b-form-group>
                </b-col>
                <b-col md="6" class="mt-1">
                    <b-form-group label="Tax Name">
                        <b-form-input v-model="taxName" placeholder="Please Enter Tax Name"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="6" class="mt-1">
                    <b-form-group label="Tax Rate In %">
                        <b-form-input v-model="taxRate" placeholder="Please Enter Tax Rate In %"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row class="border">
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Name #1">
                        <b-form-input v-model="subTaxName1" placeholder="Sub Tax Name #1"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Rate #1 In %">
                        <b-form-input v-model="subTaxRate1" placeholder="Sub Tax Rate #1 In %"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Name #2">
                        <b-form-input v-model="subTaxName2" placeholder="Sub Tax Name #2"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Rate #2 In %">
                        <b-form-input v-model="subTaxRate2" placeholder="Sub Tax Rate #2 In %"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Name #3">
                        <b-form-input v-model="subTaxName3" placeholder="Tax Label #3"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Rate #3 In %">
                        <b-form-input v-model="subTaxRate3" placeholder="Sub Tax Rate #3 In %"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Name #4">
                        <b-form-input v-model="subTaxName4" placeholder="Tax Label #4"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="3" class="mt-1">
                    <b-form-group label="Sub Tax Rate #4 In %">
                        <b-form-input v-model="subTaxRate4" placeholder="Sub Tax Rate #4 In %"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="4" class="mt-2">
                    <b-form-group>
                        <b-form-checkbox v-model="exclusiveTaxCalculation" >Is Exclusive Tax Calculation
                        </b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col md="2" class="mt-2">
                    <b-form-group>
                        <b-form-checkbox v-model="defaultTax">Is Default Tax</b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col md="2" class="mt-2">
                    <b-form-group>
                        <b-form-checkbox v-model="taxEnable">Tax Enable</b-form-checkbox>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-button variant="primary" class="mt-1" @click="addUpdateTaxRates">{{ addUpdateButton }}</b-button>
                </b-col>
            </b-row>
            <hr>
        </b-form>
        <div>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                enabled: true,
                externalQuery: searchTerm
            }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">

                    <span v-if="props.column.field === 'defaultTax'" class="text-nowrap">
                        <span v-if="props.row.defaultTax === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.defaultTax === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>
                    <span v-else-if="props.column.field === 'taxEnabel'" class="text-nowrap">
                        <span v-if="props.row.taxEnabel === true" class="text-nowrap">
                            <feather-icon icon="CheckIcon" class="isActive" />
                        </span>
                        <span v-if="props.row.taxEnabel === false" class="text-nowrap">
                            <feather-icon icon="XIcon" class="isInActive" />
                        </span>
                    </span>

                    <span v-else-if="props.column.field === 'exclusiveTaxCalculation'" class="text-nowrap">
                        <span v-if="props.row.exclusiveTaxCalculation === true" class="text-nowrap">
                            Exclusive
                        </span>
                        <span v-if="props.row.exclusiveTaxCalculation === false" class="text-nowrap">
                            Inclusive
                        </span>
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span>
                            <b-button @click="editButton(props.row.id,props.row.store.id,props.row.store.storeName, props.row.taxName, props.row.taxRate, props.row.subTaxName1, props.row.subTaxRate1, props.row.subTaxName2, props.row.subTaxRate2, props.row.subTaxName3, props.row.subTaxRate3, props.row.subTaxName4, props.row.subTaxRate4, props.row.defaultTax, props.row.exclusiveTaxCalculation, props.row.taxEnabel)"
                                variant="flat-primary"><feather-icon icon="EditIcon"
                                    class="text-body align-middle mr-25" /></b-button>
                            <b-button @click="deleteTaxRates(props.row.id)" variant="flat-primary"><feather-icon
                                    icon="TrashIcon" class="text-body align-middle mr-25" /></b-button>
                        </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox, BDropdownItem, BDropdown, BFormSelect, BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { mkdirSync } from 'fs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormInput,BFormSelect, BPagination, BButton, vSelect, BFormCheckbox, BDropdown, BDropdownItem, VueGoodTable
    },
    data() {
        return {
            id: 0,
            taxName: '',
            taxRate: '',
            subTaxName1: '',
            subTaxRate1: '',
            subTaxName2: '',
            subTaxRate2: '',
            subTaxName3: '',
            subTaxRate3: '',
            subTaxName4: '',
            subTaxRate4: '',
            defaultTax: false,
            taxEnable:false,
            exclusiveTaxCalculation: false,
            addUpdateButton: 'Add',
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Store Name',
                    field: 'store.storeName'
                },
                {
                    label: 'Is Default Tax',
                    field: 'defaultTax',
                },
                {
                    label: 'Tax Name',
                    field: 'taxName',
                },
                {
                    label: 'Tax %',
                    field: 'taxRate',
                },
                {
                    label: 'Sub Tax #1 Name',
                    field: 'subTaxName1',
                },
                {
                    label: 'Sub Tax #1 %',
                    field: 'subTaxRate1',
                },
                {
                    label: 'Sub Tax #2 Name',
                    field: 'subTaxName2',
                },
                {
                    label: 'Sub Tax #2 %',
                    field: 'subTaxRate2',
                },
                {
                    label: 'Sub Tax #3 Name',
                    field: 'subTaxName3',
                },
                {
                    label: 'Sub Tax #3 %',
                    field: 'subTaxRate3',
                },
                {
                    label: 'Sub Tax #4 Name',
                    field: 'subTaxName4',
                },
                {
                    label: 'Sub Tax #4 %',
                    field: 'subTaxRate4',
                },
                {
                    label: 'Tax Calaculation',
                    field: 'exclusiveTaxCalculation'
                },
                {
                    label: 'Tax Enable',
                    field:'taxEnabel'
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },
        }
    },
    methods: {
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
            self.storeName = { 'id': self.$store.state.storeId, 'storeName': self.$store.state.storeName };
        },
        getTaxRates() {
            this.id = 0;
            this.addUpdateButton = "Add";
            this.storeName={'id':this.$store.state.storeId,'storeName':this.$store.state.storeName};
            this.taxName = '';
            this.taxRate = '';
            this.subTaxName1 = '';
            this.subTaxRate1 = '';
            this.subTaxName2 = '';
            this.subTaxRate2 = '';
            this.subTaxName3 = '';
            this.subTaxRate3 = '';
            this.subTaxName4 = '';
            this.subTaxRate4 = '';
            this.defaultTax = false;
            this.exclusiveTaxCalculation = false;
            this.taxEnable = false;
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/masters/taxRates', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        addUpdateTaxRates() {
            let self = this;
            if (!(self.taxName == '') && !(self.taxRate == '')) {
                if (self.addUpdateButton == 'Add') {
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "store":{'id':self.storeName.id,'storeName':self.storeName.storeName},
                        "taxName": self.taxName, "taxRate": self.taxRate,
                        "subTaxName1": self.subTaxName1, "subTaxRate1": self.subTaxRate1,
                        "subTaxName2": self.subTaxName2, "subTaxRate2": self.subTaxRate2,
                        "subTaxName3": self.subTaxName3, "subTaxRate3": self.subTaxRate3,
                        "subTaxName4": self.subTaxName4, "subTaxRate4": self.subTaxRate4,
                        "defaultTax": self.defaultTax, "exclusiveTaxCalculation": self.exclusiveTaxCalculation,
                        "taxEnabel": self.taxEnable
                    });
                    axios(api.getApi('Post', '/masters/taxRates', data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Tax Rates Added Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })
                            self.getTaxRates();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    let self = this;
                    var axios = require('axios');
                    var data = JSON.stringify({
                        "store":{'id':self.storeName.id,'storeName':self.storeName.storeName},
                        "taxName": self.taxName, "taxRate": self.taxRate,
                        "subTaxName1": self.subTaxName1, "subTaxRate1": self.subTaxRate1,
                        "subTaxName2": self.subTaxName2, "subTaxRate2": self.subTaxRate2,
                        "subTaxName3": self.subTaxName3, "subTaxRate3": self.subTaxRate3,
                        "subTaxName4": self.subTaxName4, "subTaxRate4": self.subTaxRate4,
                        "defaultTax": self.defaultTax, "exclusiveTaxCalculation": self.exclusiveTaxCalculation,
                        "taxEnabel": self.taxEnable
                    });
                    axios(api.getApi('put', '/masters/taxRates/' + self.id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getTaxRates();
                            self.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: `Tax Rates Updated Successfully!`,
                                    icon: 'SaveIcon',
                                    variant: 'success',
                                },
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Enter Tax Name And Tax Rates.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }
        },
        deleteTaxRates(id) {
            let self = this;
            this.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/masters/taxRates/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getTaxRates();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },
        editButton(id,storeId,storeName, taxName, taxRate, subTaxName1, subTaxRate1, subTaxName2, subTaxRate2, subTaxName3, subTaxRate3, subTaxName4, subTaxRate4, defaultTax, exclusiveTaxCalculation,taxEnable) {
            this.id = id;
            this.addUpdateButton = 'Update';
            this.storeName={'id':storeId,'storeName':storeName};
            this.taxName = taxName;
            this.taxRate = taxRate;
            this.subTaxName1 = subTaxName1;
            this.subTaxRate1 = subTaxRate1;
            this.subTaxName2 = subTaxName2;
            this.subTaxRate2 = subTaxRate2;
            this.subTaxName3 = subTaxName3;
            this.subTaxRate3 = subTaxRate3;
            this.subTaxName4 = subTaxName4;
            this.subTaxRate4 = subTaxRate4;
            this.defaultTax = defaultTax;
            this.exclusiveTaxCalculation = exclusiveTaxCalculation;
            this.taxEnable=taxEnable;
        }
    },
    created() {
        let self = this;
        self.getStoreName();
        self.getTaxRates();
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.isActive {
    color: green;
    width: 26px;
    height: 26px;
}

.isInActive {
    color: red;
    width: 26px;
    height: 26px;
}
</style>