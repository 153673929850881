<template>
    <b-card title="Customer At Risk">
        <b-form>
            <b-col md="12">
                <b-row>
                    <p>Customers @ Risk report use complex mathematics formulas to predict next order from a customer and show you a list of customers whose orders are overdue. Below configuration allows you to tweak Customers @ Risk report results.</p>
                </b-row>
            </b-col>
            <hr>
                <b-row>
                    <b-col md="4">
                        <b-form-group label="Order Frequency Filter">
                            <v-select value="6"></v-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Multiplication Factor">
                            <v-select value="1.5x"></v-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <b-form-group label="Days">
                            <v-select value="365 Days"></v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            <b-row>
                <b-col class="text-center">
                    <b-button variant="primary">
                        <feather-icon icon="EditIcon" class="mr-50" />Update Customer At Risk</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol, BFormGroup, BFormInput,
    BButton, BLink, BFormRadio, BFormCheckbox, BFormSpinbutton, BFormFile, BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormInput,
        BButton, BLink, BFormRadio, BFormCheckbox, BFormSpinbutton, BFormFile, BFormTextarea, vSelect
    }
}
</script>