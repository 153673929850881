<template>
    <b-card title="New Order Screen Configurations">
        <b-form>
            <b-row>
                <b-col md="12">
                    <b-form-group label="Store Name">
                        <v-select v-model="storeName" label="storeName" :options="storeNameOptions"></v-select>
                    </b-form-group>
                </b-col>
                <!-- <b-col md="3">
                    <b-form-group label="Start Order From">
                        <b-form-input v-model="orderStartFrom" placeholder="Order No Prefix" />
                    </b-form-group>
                </b-col> -->
                <b-col md="4">
                    <b-form-group label="Order No Prefix (3 Letters Only)">
                        <b-form-input v-model="orderNoPrifix" @input="orderNoPrefixCheckLetters"
                            placeholder="Order No Prefix" style="text-transform:uppercase;"/>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Delivery Date Offset">
                        <b-form-input v-model="deleveryDateOffset" placeholder="Delivery Date Offset"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Delivery Time">
                        <b-time v-model="deliveryTime"></b-time>
                        <!-- <flat-pickr placeholder="Please Select Delivery Time" v-model="deliveryTime"
                            class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', }" /> -->
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="6">
                    <b-form-checkbox v-model="includeNameInOrderReceipt" switch> Include Name In Order Receipt
                    </b-form-checkbox>
                    <b-form-checkbox v-model="includeAddressInOrderReceipt" switch> Include Address In Order Receipt
                    </b-form-checkbox>
                    <b-form-checkbox v-model="includeMobileInOrderReceipt" switch> Include Mobile No In Order Receipt
                    </b-form-checkbox>
                </b-col>
                <!-- <b-col md="4">
                    <b-form-checkbox v-model="includeMembershipIdInOrderReceipt" switch> Include Membership Id In Order
                        Receipt
                    </b-form-checkbox>
                    <b-form-checkbox v-model="includeCustomerCodeInOrderReceipt" switch> Include Customer Code In Order
                        Receipt
                    </b-form-checkbox>
                </b-col> -->
                <b-col md="6">
                    <b-form-checkbox v-model="discountAllow" switch> Discount Allowed
                    </b-form-checkbox>
                    <b-form-checkbox v-model="discountTypeIsFlat" switch> Discount Type Is Flat
                    </b-form-checkbox>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="4">
                    <b-form-group label="Urgent Name 1">
                        <b-form-input v-model="urgentName1" placeholder="Please Enter Urgent Name 1"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Urgent Rate 1">
                        <b-form-input v-model="urgentRate1" placeholder="Please Enter Urgent Rate 1"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Urgent Day Offset 1">
                        <b-form-input v-model="urgentDayOffset1" placeholder="Please Enter Urgent Day Offset 1">
                        </b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Urgent Name 2">
                        <b-form-input v-model="urgentName2" placeholder="Please Enter Urgent Name 1"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Urgent Rate 2">
                        <b-form-input v-model="urgentRate2" placeholder="Please Enter Urgent Rate 1"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Urgent Day Offset 2">
                        <b-form-input v-model="urgentDayOffset2" placeholder="Please Enter Urgent Day Offset 1">
                        </b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="6">
                    <b-form-checkbox v-model="scaneGarmentsOnDelevery" switch> Scan Garments On Delivery
                    </b-form-checkbox>
                    <b-form-checkbox v-model="discountRounded" switch> Discount Rounded
                    </b-form-checkbox>
                    <b-form-checkbox v-model="garmentIconShow" switch> Garment Icon Show
                    </b-form-checkbox>
                    <!-- <b-form-checkbox v-model="orderNotesMandatory" switch> Order Notes Mandatory
                    </b-form-checkbox>
                    <b-form-checkbox v-model="workshopNoteMandatory" switch> Work shop Note Mandatory
                    </b-form-checkbox>
                    <b-form-checkbox v-model="areaLocationMandatory" switch> Area Location Mandatory
                    </b-form-checkbox>
                    <b-form-checkbox v-model="confirmationForZeroPriceForGarment" switch> Confirmation For Zero Price
                        For
                        Garment
                    </b-form-checkbox> -->
                   
                </b-col>
                <b-col md="6">
                    <!-- <b-form-checkbox v-model="emailIdMandatory" switch> Email Id Mandatory
                    </b-form-checkbox> -->
                    <!-- <b-form-checkbox v-model="enableDueDateConfirmation" switch> Enable Due Date Confirmation
                    </b-form-checkbox> -->
                    <!-- <b-form-checkbox v-model="remarkMandatoryOnEdited" switch> Remark Mandatory On Edited
                    </b-form-checkbox> -->
                    <!-- <b-form-checkbox v-model="garmentInspectedPersonMandatory" switch> Garment Inspected Person
                        Mandatory
                    </b-form-checkbox> -->
                    <!-- <b-form-checkbox v-model="showEachServiceWorkLoadForDueDate" switch> Show Each Service Work Load For
                        Due
                        Date
                    </b-form-checkbox> -->
                    <!-- <b-form-checkbox v-model="updateRateInPriceListOnSave" switch> Update Rate In Price List On Save
                    </b-form-checkbox> -->
                    <b-form-checkbox v-model="enableBeepSoundsForGarmentScanning" switch> Enable Beep Sounds For Garment
                        Scanning
                    </b-form-checkbox>
                    <b-form-checkbox v-model="autoPrintInvoiceOnOrderGeneration" switch> Auto Print Invoice On Order
                        Generation
                    </b-form-checkbox>
                    <b-form-checkbox v-model="autoPrintGarmentTagOnOrderGeneration" switch> Auto Print Garment Tag On
                        Order
                        Generation
                    </b-form-checkbox>
                    <!-- <b-form-checkbox v-model="mobileNoMandatoryForCustomer" switch> Mobile No Mandatory For Customer
                    </b-form-checkbox> -->
                    <!-- <b-form-checkbox v-model="showPreviewOfOrderAfterSubmission" switch> Show Preview Of Order After
                        Submission
                    </b-form-checkbox> -->
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col class="text-center">
                    <b-button variant="primary" @click="addUpdateStoreOrderConfiguration">{{ addUpdateButton }}
                    </b-button>
                </b-col>
            </b-row>
        </b-form>
        <div>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                enabled: true,
                externalQuery: searchTerm
            }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">
                    <!-- Column Printer Delivery-->
                    <span v-if="props.column.field === 'deliveryReceiptPrinterType'">
                        <span>
                            {{ printerTypeForTable(props.row.deliveryReceiptPrinterType) }}
                        </span>
                    </span>

                    <!-- Column Printer Receipt-->
                    <span v-else-if="props.column.field === 'receiptPrintPrinterType'">
                        <span>
                            {{ printerTypeForTable(props.row.receiptPrintPrinterType) }}
                        </span>
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span>
                            <b-button @click="getOrderConfiguration(props.row.store.id)"
                                variant="flat-primary"><feather-icon icon="EditIcon"
                                    class="text-body align-middle mr-25" /></b-button>
                            <b-button @click="deleteStoreOrderConfiguration(props.row.id)"
                                variant="flat-primary"><feather-icon icon="TrashIcon"
                                    class="text-body align-middle mr-25" /></b-button>
                        </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </b-card>
</template>

<script>
import {
    BCard, BRow, BCol, BForm, BButton, BFormGroup, BFormSelect, BPagination, BTime, BFormInput, BCardGroup, BContainer, BFormCheckbox, BFormRadio, BInputGroup, BInputGroupPrepend, BInputGroupAppend
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'


export default {
    components: {
        BButton, BCard, BCol, BRow, BForm, BFormGroup, BFormSelect, BPagination,
        BFormInput, BContainer, BCardGroup, BFormRadio,
        BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormCheckbox, BTime,
        vSelect, flatPickr, VueGoodTable
    },
    data() {
        return {
            storeNameOptions: [],
            storeName: '',
            orderNoPrifix: '',
            orderStartFrom: 0,
            deleveryDateOffset: 0,
            deliveryTime: null,
            includeNameInOrderReceipt: false,
            includeAddressInOrderReceipt: false,
            includeMobileInOrderReceipt: false,
            includeMembershipIdInOrderReceipt: false,
            includeCustomerCodeInOrderReceipt: false,
            discountAllow: false,
            discountTypeIsFlat: false,
            urgentName1: '',
            urgentName2: '',
            urgentRate1: 0,
            urgentRate2: 0,
            urgentDayOffset1: 0,
            urgentDayOffset2: 0,
            scaneGarmentsOnDelevery: false,
            discountRounded: false,
            garmentIconShow: false,
            orderNotesMandatory: false,
            workshopNoteMandatory: false,
            areaLocationMandatory: false,
            emailIdMandatory: false,
            enableDueDateConfirmation: false,
            remarkMandatoryOnEdited: false,
            garmentInspectedPersonMandatory: false,
            showEachServiceWorkLoadForDueDate: false,
            updateRateInPriceListOnSave: false,
            confirmationForZeroPriceForGarment: false,
            autoPrintInvoiceOnOrderGeneration: false,
            autoPrintGarmentTagOnOrderGeneration: false,
            enableBeepSoundsForGarmentScanning: false,
            mobileNoMandatoryForCustomer: false,
            showPreviewOfOrderAfterSubmission: false,
            addUpdateButton: 'Add',
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Store Name',
                    field: 'store.storeName',
                },
                // {
                //     label: 'Start Order From',
                //     field: 'orderStartFrom',
                // },
                {
                    label: 'Order Prefix No',
                    field: 'orderNoPrifix',
                },
                {
                    label: 'Delivery Date Off Set',
                    field: 'deleveryDateOffset',
                },
                {
                    label: 'Delivery Time',
                    field: 'deliveryTime',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',

        }
    },
    methods: {
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            self.storeName = { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName }

        },
        getStoreOrderConfiguration() {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/storeOrderConfiguration', data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOrderConfiguration(storeId) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/storeOrderConfiguration/' + storeId, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    self.addUpdateButton = 'Update';
                    self.storeName = { 'id': response.data.store.id, 'storeName': response.data.store.storeName }
                    self.orderNoPrifix = response.data.orderNoPrifix;
                    self.orderStartFrom = response.data.orderStartFrom;
                    self.deleveryDateOffset = response.data.deleveryDateOffset;
                    self.deliveryTime = response.data.deliveryTime;
                    self.includeNameInOrderReceipt = response.data.includeNameInOrderReceipt;
                    self.includeAddressInOrderReceipt = response.data.includeAddressInOrderReceipt;
                    self.includeMobileInOrderReceipt = response.data.includeMobileInOrderReceipt;
                    self.includeMembershipIdInOrderReceipt = response.data.includeMembershipIdInOrderReceipt;
                    self.includeCustomerCodeInOrderReceipt = response.data.includeCustomerCodeInOrderReceipt;
                    self.discountAllow = response.data.discountAllow;
                    self.discountTypeIsFlat = response.data.discountTypeIsFlat;
                    self.urgentName1 = response.data.urgentName1;
                    self.urgentName2 = response.data.urgentName2;
                    self.urgentRate1 = response.data.urgentRate1;
                    self.urgentRate2 = response.data.urgentRate2;
                    self.urgentDayOffset1 = response.data.urgentDayOffset1;
                    self.urgentDayOffset2 = response.data.urgentDayOffset2;
                    self.scaneGarmentsOnDelevery = response.data.scaneGarmentsOnDelevery;
                    self.discountRounded = response.data.discountRounded;
                    self.garmentIconShow = response.data.garmentIconShow;
                    self.orderNotesMandatory = response.data.orderNotesMandatory;
                    self.workshopNoteMandatory = response.data.workshopNoteMandatory;
                    self.areaLocationMandatory = response.data.areaLocationMandatory;
                    self.emailIdMandatory = response.data.emailIdMandatory;
                    self.enableDueDateConfirmation = response.data.enableDueDateConfirmation;
                    self.remarkMandatoryOnEdited = response.data.remarkMandatoryOnEdited;
                    self.garmentInspectedPersonMandatory = response.data.garmentInspectedPersonMandatory;
                    self.showEachServiceWorkLoadForDueDate = response.data.showEachServiceWorkLoadForDueDate;
                    self.updateRateInPriceListOnSave = response.data.updateRateInPriceListOnSave;
                    self.confirmationForZeroPriceForGarment = response.data.confirmationForZeroPriceForGarment;
                    self.autoPrintInvoiceOnOrderGeneration = response.data.autoPrintInvoiceOnOrderGeneration;
                    self.autoPrintGarmentTagOnOrderGeneration = response.data.autoPrintGarmentTagOnOrderGeneration;
                    self.enableBeepSoundsForGarmentScanning = response.data.enableBeepSoundsForGarmentScanning;
                    self.mobileNoMandatoryForCustomer = response.data.mobileNoMandatoryForCustomer;
                    self.showPreviewOfOrderAfterSubmission = response.data.showPreviewOfOrderAfterSubmission;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        addUpdateStoreOrderConfiguration() {
            let self = this;
            if (self.addUpdateButton == 'Add') {
                var axios = require('axios');
                var data = JSON.stringify({
                    "store": { 'id': self.storeName.id, 'storeName': self.storeName.storeName },
                    "orderNoPrifix": self.orderNoPrifix,
                    "orderStartFrom": self.orderStartFrom,
                    "deleveryDateOffset": self.deleveryDateOffset,
                    "deliveryTime": self.deliveryTime,
                    "includeNameInOrderReceipt": self.includeNameInOrderReceipt,
                    "includeAddressInOrderReceipt": self.includeAddressInOrderReceipt,
                    "includeMobileInOrderReceipt": self.includeMobileInOrderReceipt,
                    "includeMembershipIdInOrderReceipt": self.includeMembershipIdInOrderReceipt,
                    "includeCustomerCodeInOrderReceipt": self.includeCustomerCodeInOrderReceipt,
                    "discountAllow": self.discountAllow,
                    "discountTypeIsFlat": self.discountTypeIsFlat,
                    "urgentName1": self.urgentName1,
                    "urgentName2": self.urgentName2,
                    "urgentRate1": self.urgentRate1,
                    "urgentRate2": self.urgentRate2,
                    "urgentDayOffset1": self.urgentDayOffset1,
                    "urgentDayOffset2": self.urgentDayOffset2,
                    "scaneGarmentsOnDelevery": self.scaneGarmentsOnDelevery,
                    "discountRounded": self.discountRounded,
                    "garmentIconShow": self.garmentIconShow,
                    "orderNotesMandatory": self.orderNotesMandatory,
                    "workshopNoteMandatory": self.workshopNoteMandatory,
                    "areaLocationMandatory": self.areaLocationMandatory,
                    "emailIdMandatory": self.emailIdMandatory,
                    "enableDueDateConfirmation": self.enableDueDateConfirmation,
                    "remarkMandatoryOnEdited": self.remarkMandatoryOnEdited,
                    "garmentInspectedPersonMandatory": self.garmentInspectedPersonMandatory,
                    "showEachServiceWorkLoadForDueDate": self.showEachServiceWorkLoadForDueDate,
                    "updateRateInPriceListOnSave": self.updateRateInPriceListOnSave,
                    "confirmationForZeroPriceForGarment": self.confirmationForZeroPriceForGarment,
                    "autoPrintInvoiceOnOrderGeneration": self.autoPrintInvoiceOnOrderGeneration,
                    "autoPrintGarmentTagOnOrderGeneration": self.autoPrintGarmentTagOnOrderGeneration,
                    "enableBeepSoundsForGarmentScanning": self.enableBeepSoundsForGarmentScanning,
                    "mobileNoMandatoryForCustomer": self.mobileNoMandatoryForCustomer,
                    "showPreviewOfOrderAfterSubmission": self.showPreviewOfOrderAfterSubmission
                });
                axios(api.getApi('Post', '/storeOrderConfiguration', data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Store Order Configuration Added Successfully!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })
                        self.getStoreOrderConfiguration();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                let self = this;
                var axios = require('axios');
                var data = JSON.stringify({
                    "store": { 'id': self.storeName.id, 'storeName': self.storeName.storeName },
                    "orderNoPrifix": self.orderNoPrifix,
                    "orderStartFrom": self.orderStartFrom,
                    "deleveryDateOffset": self.deleveryDateOffset,
                    "deliveryTime": self.deliveryTime,
                    "includeNameInOrderReceipt": self.includeNameInOrderReceipt,
                    "includeAddressInOrderReceipt": self.includeAddressInOrderReceipt,
                    "includeMobileInOrderReceipt": self.includeMobileInOrderReceipt,
                    "includeMembershipIdInOrderReceipt": self.includeMembershipIdInOrderReceipt,
                    "includeCustomerCodeInOrderReceipt": self.includeCustomerCodeInOrderReceipt,
                    "discountAllow": self.discountAllow,
                    "discountTypeIsFlat": self.discountTypeIsFlat,
                    "urgentName1": self.urgentName1,
                    "urgentName2": self.urgentName2,
                    "urgentRate1": self.urgentRate1,
                    "urgentRate2": self.urgentRate2,
                    "urgentDayOffset1": self.urgentDayOffset1,
                    "urgentDayOffset2": self.urgentDayOffset2,
                    "scaneGarmentsOnDelevery": self.scaneGarmentsOnDelevery,
                    "discountRounded": self.discountRounded,
                    "garmentIconShow": self.garmentIconShow,
                    "orderNotesMandatory": self.orderNotesMandatory,
                    "workshopNoteMandatory": self.workshopNoteMandatory,
                    "areaLocationMandatory": self.areaLocationMandatory,
                    "emailIdMandatory": self.emailIdMandatory,
                    "enableDueDateConfirmation": self.enableDueDateConfirmation,
                    "remarkMandatoryOnEdited": self.remarkMandatoryOnEdited,
                    "garmentInspectedPersonMandatory": self.garmentInspectedPersonMandatory,
                    "showEachServiceWorkLoadForDueDate": self.showEachServiceWorkLoadForDueDate,
                    "updateRateInPriceListOnSave": self.updateRateInPriceListOnSave,
                    "confirmationForZeroPriceForGarment": self.confirmationForZeroPriceForGarment,
                    "autoPrintInvoiceOnOrderGeneration": self.autoPrintInvoiceOnOrderGeneration,
                    "autoPrintGarmentTagOnOrderGeneration": self.autoPrintGarmentTagOnOrderGeneration,
                    "enableBeepSoundsForGarmentScanning": self.enableBeepSoundsForGarmentScanning,
                    "mobileNoMandatoryForCustomer": self.mobileNoMandatoryForCustomer,
                    "showPreviewOfOrderAfterSubmission": self.showPreviewOfOrderAfterSubmission
                });
                axios(api.getApi('put', '/storeOrderConfiguration/' + self.storeName.id, data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.getStoreOrderConfiguration();
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Store Order Configuration Updated Successfully!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        deleteStoreOrderConfiguration(id) {
            let self = this;
            self.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/storeOrderConfiguration/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getStoreOrderConfiguration();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        },
        orderNoPrefixCheckLetters(text) {
            let self = this;
            var value = ''
            if (text.length > 3) {
                value = text.slice(0, 3);
                text = value;

                self.$swal({
                    title: '',
                    text: "Enter 3 letters only!",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                }).then(result => {
                    if (result.value) {
                        self.orderNoPrifix = text;
                    }
                })
            }
        }

    },
    created() {
        let self = this;
        self.getStoreName();
        self.getStoreOrderConfiguration();

    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>