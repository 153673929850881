<template>
    <b-card title="Set Invoice Label">
        <b-form>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Tax">
                        <b-form-input placeholder="Please Enter Tax Label"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="6">
                    <b-form-group label="Item">
                        <b-form-input placeholder="Please Enter Item Label"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Service">
                        <b-form-input placeholder="Please Enter Service Label"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="6">
                    <b-form-group label="Discount">
                        <b-form-input placeholder="Please Enter Discount Label"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Total Pieces">
                        <b-form-input placeholder="Please Enter Total Pieces Label"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="6">
                    <b-form-group label="Quantity">
                        <b-form-input placeholder="Please Enter Quantity Label"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="6">
                    <b-form-group label="Advance">
                        <b-form-input placeholder="Please Enter Advance Label"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="6">
                    <b-form-group label="Currency">
                        <b-form-input placeholder="Please Enter Currency Label"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6">
                    <b-form-group label="Gross Amount">
                        <b-form-input placeholder="Please Enter Gross Amount Label"></b-form-input>
                    </b-form-group>

                </b-col>
                <b-col md="6">
                    <b-form-group label="Balance Amount">
                        <b-form-input placeholder="Please Enter Balance Amount Label"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col md="12">
                        <b-button variant="primary" class="mt-2 mr-2"><feather-icon icon="SaveIcon" class="mr-50" /> Save</b-button>
                        <b-button variant="secondary" class="mt-2 mr-2"><feather-icon icon="EditIcon" class="mr-50" /> Reset</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton
} from 'bootstrap-vue'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormInput, BButton
    }
}
</script>