<template>
    <b-card title="Advance Setting">
        <b-form>
            <b-col md="6">
                <b-row>
                    <b-form-checkbox checked="false" switch class="ml-1"></b-form-checkbox>
                    <b-form-checkbox checked="false" switch class="ml-1"></b-form-checkbox>
                </b-row>
            </b-col>


            <hr>
            <b-card title="Regional Language Garment Name">
                <b-row>
                    <b-col md="6">
                        <b-form-group>
                            <v-select value="Today vs Yesterday"></v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-card>
            <hr>

            <b-card title="Regional Language Garment Name">
                <b-col md="6">
                <b-row>
                    <b-form-checkbox checked="false" switch class="ml-1"></b-form-checkbox>
                    <b-form-checkbox checked="false" switch class="ml-1"></b-form-checkbox>
                </b-row>
            </b-col>
            </b-card>
            <hr>
            <b-card>
                <b-row>
                    <b-col md="6">
                        <b-form-checkbox checked="false" switch class="ml-1"></b-form-checkbox>
                    </b-col>
                </b-row>
            </b-card>
            <b-row>
                <b-col class="text-center">
                    <b-button variant="primary"><feather-icon icon="EditIcon" class="mr-50" />Update Advance Setting</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol, BFormGroup, BFormInput,
    BButton, BLink, BFormRadio, BFormCheckbox, BFormSpinbutton, BFormFile, BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormInput,
        BButton, BLink, BFormRadio, BFormCheckbox, BFormSpinbutton, BFormFile, BFormTextarea, vSelect
    }
}
</script>