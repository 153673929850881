<template>
    <b-card title="Receipts Configurations">
        <b-form>
            <b-row>
                <b-col md="4">
                    <!-- <b-card title="Printer configurations - Order">
                        <b-col> -->
                    <b-form-group label="Store Name">
                        <v-select placeholder="Select Store" v-model="storeName" label="storeName"
                            :options="storeNameOptions"></v-select>
                    </b-form-group>
                    <!-- </b-col>
                    </b-card> -->
                </b-col>
                <b-col md="4">
                    <!-- <b-card title="Printer configurations - Order">
                        <b-col> -->
                    <b-form-group label="Default Printer Receipt">
                        <v-select placeholder="Select Receipt Printer" v-model="receiptPrintPrinterType" label="name"
                            :options="receiptPrintPrinterTypeOptions"></v-select>
                    </b-form-group>
                    <!-- </b-col>
                    </b-card> -->
                </b-col>
                <b-col md="4">
                    <!-- <b-card title="Printer configurations - Delivery">
                        <b-col> -->
                    <b-form-group label="Default Printer Delivery">
                        <v-select placeholder="Select Delivery Printer" v-model="deliveryReceiptPrinterType"
                            label="name" :options="deliveryReceiptPrinterTypeOptions"></v-select>
                    </b-form-group>
                    <!-- </b-col>
                    </b-card> -->
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col md="4">
                    <b-form-checkbox v-model="printStoreInvoiceCopy" switch>Print Store Invoice Copy
                    </b-form-checkbox>
                    <b-form-checkbox v-model="printSubTaxDetails" switch>Print Sub Tax Details
                    </b-form-checkbox>
                    <!-- <b-form-checkbox v-model="printPackageDetails" switch>Print Package Details
                    </b-form-checkbox> -->
                    <!-- <b-form-checkbox v-model="showInclusiveOfAllTax" switch>Show Inclusive Of All Tax
                    </b-form-checkbox> -->
                    <b-form-checkbox v-model="printPreviousDueOnInvoice" switch>Print Previous Due On Invoice
                    </b-form-checkbox>
                </b-col>
                <b-col md="4">
                    <b-form-checkbox v-model="printDueDate" switch>Print Due Date</b-form-checkbox>
                    
                    <b-form-checkbox v-model="printOrderCriationTime" switch>Print Order Creation Time
                    </b-form-checkbox>
                    <!-- <b-form-checkbox v-model="printCustomerSignature" switch>Print Customer Signature
                    </b-form-checkbox> -->
                    <b-form-checkbox v-model="printTaxAmountOnInvoice" switch>Print Tax Amount On Invoice
                    </b-form-checkbox>
                </b-col>
                <b-col md="4">
                    
                    <!-- <b-form-checkbox v-model="printCustomerSignatureBox" switch>Print Customer Signature Box
                    </b-form-checkbox> -->
                    <b-form-checkbox v-model="printTermConditionOnStoreCopy" switch>Print Term Condition On Store
                        Copy</b-form-checkbox>
                    <b-form-checkbox v-model="printTermConditionOnCustomerCopy" switch>Print Term Condition On
                        Customer Copy</b-form-checkbox>

                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-center">
                    <b-button variant="primary" class="mt-1" @click="addUpdateReceipt">{{ addUpdateButton }}</b-button>
                </b-col>
            </b-row>
            <hr>
            <div>
                <!-- search input -->
                <div class="custom-search d-flex justify-content-end">
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                class="d-inline-block" />
                        </div>
                    </b-form-group>
                </div>

                <!-- table -->
                <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm
                }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                    <template slot="table-row" slot-scope="props">
                        <!-- Column Printer Delivery-->
                        <span v-if="props.column.field === 'deliveryReceiptPrinterType'">
                            <span>
                                {{ printerTypeForTable(props.row.deliveryReceiptPrinterType) }}
                            </span>
                        </span>

                        <!-- Column Printer Receipt-->
                        <span v-else-if="props.column.field === 'receiptPrintPrinterType'">
                            <span>
                                {{ printerTypeForTable(props.row.receiptPrintPrinterType) }}
                            </span>
                        </span>

                        <!-- Column: Action -->
                        <span v-else-if="props.column.field === 'action'">
                            <span>
                                <b-button @click="getReceiptConfiguration(props.row.store.id)"
                                    variant="flat-primary"><feather-icon icon="EditIcon"
                                        class="text-body align-middle mr-25" /></b-button>
                                <b-button @click="deleteReceiptConfiguration(props.row.id)"
                                    variant="flat-primary"><feather-icon icon="TrashIcon"
                                        class="text-body align-middle mr-25" /></b-button>
                            </span>
                        </span>

                        <!-- Column: Common -->
                        <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                        </span>
                    </template>

                    <!-- pagination -->
                    <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap ">
                                    Showing 1 to
                                </span>
                                <b-form-select v-model="pageLength" :options="['10', '20', '50','100']" class="mx-1"
                                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                <span class="text-nowrap"> of {{ props.total }} entries </span>
                            </div>
                            <div>
                                <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                    last-number align="right" prev-class="prev-item" next-class="next-item"
                                    class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                    <template #prev-text>
                                        <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                        <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                            </div>
                        </div>
                    </template>
                </vue-good-table>
            </div>

        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol, BFormGroup, BFormInput,BFormSelect, BPagination,
    BButton, BLink, BFormRadio, BFormCheckbox, BFormSpinbutton, BFormFile, BFormTextarea, BSidebar, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import InvoiceLabels from './InvoiceLabels.vue'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import api from '@/store/api'
import util from '@/store/utils'

export default {
    components: {
        BCard, BForm, BRow, BCol, BFormGroup, BFormInput,
        BButton, BLink, BFormRadio, BFormCheckbox, BFormSpinbutton,BFormSelect, BPagination,
        BFormFile, BFormTextarea, vSelect, BSidebar, VBToggle, InvoiceLabels, VueGoodTable
    },
    data() {
        return {
            addUpdateButton: 'Add',
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },
            receiptPrintPrinterTypeOptions: [],
            receiptPrintPrinterType: {'id':0,'name':'---Select---'},
            deliveryReceiptPrinterTypeOptions: [],
            deliveryReceiptPrinterType: {'id':0,'name':'---Select---'},
            printStoreInvoiceCopy: false,
            printSubTaxDetails: false,
            printPackageDetails: false,
            showInclusiveOfAllTax: false,
            printOrderCriationTime: false,
            printCustomerSignature: false,
            printDueDate: false,
            printPreviousDueOnInvoice: false,
            printTaxAmountOnInvoice: false,
            printCustomerSignatureBox: false,
            printTermConditionOnCustomerCopy: false,
            printTermConditionOnStoreCopy: false,

            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Store Name',
                    field: 'store.storeName',
                },
                {
                    label: 'Receipt Printer',
                    field: 'receiptPrintPrinterType',
                },
                {
                    label: 'Delivery Printer',
                    field: 'deliveryReceiptPrinterType',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            searchTerm: '',

        }
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    methods: {
        getPrinterType() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getPrintersType', data))
                .then(function (response) {
                    let i=0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.receiptPrintPrinterTypeOptions.push({'id':i,'name':response.data[index]});
                        self.deliveryReceiptPrinterTypeOptions.push({'id':i,'name':response.data[index]})
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        printerTypeForTable(printerTypeId) {
            if (printerTypeId > 0) {
                const object = this.deliveryReceiptPrinterTypeOptions.find(obj => obj.id == printerTypeId);
                return object.name;
            } else {
                return '';
            }
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;

                })
                .catch(function (error) {
                    console.log(error);
                });
            // self.storeName = { 'id': self.$store.state.storeId, 'storeName': self.$store.state.storeName };
        },
        getStoreReceiptConfiguration() {

            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/storeReceiptConfiguration', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.rows = response.data;

                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getReceiptConfiguration(storeId) {

            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/storeReceiptConfiguration/'+storeId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.addUpdateButton='Update';
                    self.storeName={'id':response.data.store.id, 'storeName':response.data.store.storeName};

                    const objectReceiptPrinter=self.receiptPrintPrinterTypeOptions.find(obj=> obj.id==response.data.receiptPrintPrinterType);
                    self.receiptPrintPrinterType={'id':objectReceiptPrinter.id ,'name':objectReceiptPrinter.name};

                    const objectdeliveryReceiptPrinter=self.deliveryReceiptPrinterTypeOptions.find(obj=> obj.id==response.data.deliveryReceiptPrinterType);
                    self.deliveryReceiptPrinterType={'id':objectdeliveryReceiptPrinter.id ,'name':objectdeliveryReceiptPrinter.name};

                    // self.receiptPrintPrinterType = response.data.receiptPrintPrinterType;
                    // self.deliveryReceiptPrinterType = response.data.deliveryReceiptPrinterType;

                    self.printStoreInvoiceCopy = response.data.printStoreInvoiceCopy;
                    self.printSubTaxDetails = response.data.printSubTaxDetails;
                    self.printPackageDetails = response.data.printPackageDetails;
                    self.showInclusiveOfAllTax = response.data.showInclusiveOfAllTax;
                    self.printOrderCriationTime = response.data.printOrderCriationTime;
                    self.printCustomerSignature = response.data.printCustomerSignature;
                    self.printDueDate = response.data.printDueDate;
                    self.printPreviousDueOnInvoice = response.data.printPreviousDueOnInvoice;
                    self.printTaxAmountOnInvoice = response.data.printTaxAmountOnInvoice;
                    self.printCustomerSignatureBox = response.data.printCustomerSignatureBox;
                    self.printTermConditionOnCustomerCopy = response.data.printTermConditionOnCustomerCopy;
                    self.printTermConditionOnStoreCopy = response.data.printTermConditionOnStoreCopy;
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        addUpdateReceipt() {
            let self = this;
            if (self.addUpdateButton == 'Add') {
                var axios = require('axios');
                var data = JSON.stringify({
                    "store": { 'id': self.storeName.id, 'name': self.storeName.storeName },
                    "receiptPrintPrinterType": self.receiptPrintPrinterType.id,
                    "deliveryReceiptPrinterType": self.deliveryReceiptPrinterType.id,
                    "printStoreInvoiceCopy": self.printStoreInvoiceCopy,
                    "printSubTaxDetails": self.printSubTaxDetails,
                    "printPackageDetails": self.printPackageDetails,
                    "showInclusiveOfAllTax": self.showInclusiveOfAllTax,
                    "printOrderCriationTime": self.printOrderCriationTime,
                    "printCustomerSignature": self.printCustomerSignature,
                    "printDueDate": self.printDueDate,
                    "printPreviousDueOnInvoice": self.printPreviousDueOnInvoice,
                    "printTaxAmountOnInvoice": self.printTaxAmountOnInvoice,
                    "printCustomerSignatureBox": self.printCustomerSignatureBox,
                    "printTermConditionOnCustomerCopy": self.printTermConditionOnCustomerCopy,
                    "printTermConditionOnStoreCopy": self.printTermConditionOnStoreCopy
                });
                axios(api.getApi('Post', '/storeReceiptConfiguration', data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Receipt Configuration Added Successfully!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })
                        self.getStoreReceiptConfiguration();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                let self = this;
                var axios = require('axios');
                var data = JSON.stringify({
                    "store": { 'id': self.storeName.id, 'name': self.storeName.storeName },
                    "receiptPrintPrinterType": self.receiptPrintPrinterType.id,
                    "deliveryReceiptPrinterType": self.deliveryReceiptPrinterType.id,
                    "printStoreInvoiceCopy": self.printStoreInvoiceCopy,
                    "printSubTaxDetails": self.printSubTaxDetails,
                    "printPackageDetails": self.printPackageDetails,
                    "showInclusiveOfAllTax": self.showInclusiveOfAllTax,
                    "printOrderCriationTime": self.printOrderCriationTime,
                    "printCustomerSignature": self.printCustomerSignature,
                    "printDueDate": self.printDueDate,
                    "printPreviousDueOnInvoice": self.printPreviousDueOnInvoice,
                    "printTaxAmountOnInvoice": self.printTaxAmountOnInvoice,
                    "printCustomerSignatureBox": self.printCustomerSignatureBox,
                    "printTermConditionOnCustomerCopy": self.printTermConditionOnCustomerCopy,
                    "printTermConditionOnStoreCopy": self.printTermConditionOnStoreCopy
                });
                axios(api.getApi('put', '/storeReceiptConfiguration/' + self.storeName.id, data))
                    .then(function (response) {
                        //console.log(JSON.stringify(response.data));
                        self.getStoreReceiptConfiguration();
                        self.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: `Receipt Configuration Updated Successfully!`,
                                icon: 'SaveIcon',
                                variant: 'success',
                            },
                        })
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        deleteReceiptConfiguration(id){
            let self = this;
            self.$swal({
                title: '',
                text: "Are You Sure You Want To Delete!",
                icon: 'error',
                customClass: {
                    confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    var axios = require('axios');
                    var data = '';
                    axios(api.getApi('delete', '/storeReceiptConfiguration/' + id, data))
                        .then(function (response) {
                            //console.log(JSON.stringify(response.data));
                            self.getStoreReceiptConfiguration();
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }
            })
        }
    },
    created() {
        let self = this;
        self.getStoreName();
        self.getPrinterType();
        self.getStoreReceiptConfiguration();
    }
}
</script>