<template>
    <b-card>
        <b-form>
            <b-row>
                <b-col>
                    <b-form-group label-for="timeZone" label="Select Time Zone">
                        <v-select id="timeZone" v-model="localOptions.country"
                             label="title"
                            :options="timeZoneOption" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    {{new Date().toLocaleString()}}
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button 
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
                    >Update Time Zone</b-button>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import {
    BCard, BForm, BRow, BCol, BButton, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    components: {
        BCard,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        vSelect,
    },
    props: {
        informationData: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            timeZoneOption: ['(UTC+05:30) Chennai, kolkata, Mumbai, new Delhi', '(UTC-07:00) mountain Time (US & Canada)'],
            localOptions: JSON.parse(JSON.stringify(this.informationData)),
        }
    },
    methods: {
        resetForm() {
            this.localOptions = JSON.parse(JSON.stringify(this.informationData))
        },
    },
}

</script>