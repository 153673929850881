<template>
  <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left">

    <!-- Set Time Zone tab -->
    <!-- <b-tab active>

      <template #title>
        <feather-icon icon="ClockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Set Time Zone</span>
      </template>

      <set-time-zone v-if="options.info" :information-data="options.info"/>
    </b-tab> -->
    <!--/ Set Time Zone tab -->

    <!-- Order Drop & Pickup tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon icon="TruckIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Order Drop & Pickup</span>
      </template>

      <order-drop-pickup />
    </b-tab>
    <!--/ Order Drop & Pickup tab -->

    <!-- Store Information -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon icon="InfoIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Store Information</span>
      </template>

      <store-information :getStoreInformation="getStoreInformation" :isEdit=isEdit />
    </b-tab>

    <!-- Receipts -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon icon="FileTextIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Receipts</span>
      </template>

      <receipt />
    </b-tab>

    <!-- Default Tax Rates -->
    <b-tab>

<!-- title -->
<template #title>
  <feather-icon icon="ListIcon" size="18" class="mr-50" />
  <span class="font-weight-bold">Terms & Conditions</span>
</template>

<receipt-terms-condition />
</b-tab>

    <!-- Default Tax Rates -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon icon="TagIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Default Tax Rates</span>
      </template>

      <default-tax-rate v-if="options.notification" :notification-data="options.notification" />
    </b-tab>

    <!-- Advance Setting tab -->

    <!-- <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Advance Setting</span>
      </template>
      <advance-setting v-if="options.general" :general-data="options.general" />
    </b-tab> -->

    <!--/ Advance Setting tab -->
    
    <!-- Customer Mobile App tab -->
    
    <!-- <b-tab>
      <template #title>
        <feather-icon icon="SmartphoneIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Customer Mobile App</span>
      </template>
      <customer-mobile-app v-if="options.general" :general-data="options.general" />
    </b-tab> -->

    <!--/ Customer Mobile App tab -->
    <!-- Customer At Risk tab -->
    
    <!-- <b-tab>
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Customer At Risk</span>
      </template>
      <customer-at-risk v-if="options.general" :general-data="options.general" />
    </b-tab> -->

    <!--/ Customer At Risk tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import SetTimeZone from './SetTimeZone.vue'
import OrderDropPickup from './OrderDropPickup.vue'
import StoreInformation from './StoreInformation.vue'
import Receipt from './Receipt.vue'
import ReceiptTermsCondition from './ReceiptTermsCondition.vue'
import DefaultTaxRate from './DefaultTaxRate.vue'
import AdvanceSetting from './AdvanceSetting.vue'
import CustomerMobileApp from './CustomerMobileApp.vue'
import CustomerAtRisk from './CustomerAtRisk.vue'

export default {
  components: {
    
    BTabs,
    BTab,
    SetTimeZone,
    OrderDropPickup,
    StoreInformation,
    Receipt,
    ReceiptTermsCondition,
    DefaultTaxRate,
    AdvanceSetting,
    CustomerMobileApp,
    CustomerAtRisk,
  },
  data() {
    return {
      options: {},
      isEdit: true,
    }
  },
  methods:{
    getStoreInformation(){
      
    }
  },
  beforeCreate() {
    this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  },
}
</script>
